export const homeObjOne = {
    id: 'about',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'chama',
    headline: 'All within one app',
    //description: 'Download the Chama app now to get access to our features and start to keep track of your group investments and safely facilitate payments.',
    description: 'Download the Chama app now, to gain access to our features and easily manage your finances, keep track of group activity, facilitate payments, remain up-to date and much more; all within one app.',
    buttonLabel: 'Get started',
    imgStart: true,
    img: require('../../images/about2.png'),
    alt: 'iphone1',
    dark: true,
    primary: true,
    darkText: true, 
}

export const homeObjTwo = {
    id: 'payments',
    lightBg: false,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Safe & Secure',
    headline: 'Payments',
    description: 'Make payments to your group using any of our trusted credit card, debit card, apple pay, google pay or mpesa payments methods.',
    buttonLabel: 'Get started',
    imgStart: false,
    img: require('../../images/payment.png'),
    alt: 'payments',
    dark: true,
    primary: true,
    darkText: true, 
}

export const homeObjThree = {
    id: 'platforms',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Globally',
    headline: 'Platforms',
    description: 'Download Chama App for free on your updated Android or IOS devices, available on the Playstore and App Store respecfully.',
    buttonLabel: 'Get started',
    imgStart: true,
    img: require('../../images/platforms.png'),
    alt: 'platforms',
    dark: true,
    primary: true,
    darkText: true, 
}