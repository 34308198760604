import React from 'react'
import Download from '../components/Download'

const DownloadPage = () => {
  return (
    <>
        {/* <h1>downloaddd</h1> */}
        <Download />

    </>
  )
}

export default DownloadPage