import React from 'react'
import Success from '../components/Success'

const SuccessPage = () => {
  return (
    <>
        
        <Success />

    </>
  )
}

export default SuccessPage