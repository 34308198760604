import React from 'react'
import Fail from '../components/Fail'

const FailPage = () => {
  return (
    <>
        
        <Fail />

    </>
  )
}

export default FailPage